<template>
    <div class="template-page">
        <basic-page :fulldata="fulldata" :pagedata="this.pageData" :bannerimg="this.bannerimg">
            <div v-if="pageData.body!=undefined" v-html="this.pageData.body[0].processed"></div>
        </basic-page>
    </div>
</template>

<script>
import { fetchSingleNode } from '../libs/drupalClient'
import BasicPage from './BasicPage.vue'

export default {
  components: { BasicPage },
    name: 'consulting',
    data: () => {
        return {
            currentPageId: "c542ae2e-d226-459d-b17d-de4a1528c63c",
            pageData: {},
            fulldata: {},
            bannerimg: null
        }
    },
    methods: {
    },
    computed: {

    },
    mounted() {
        fetchSingleNode('page', this.currentPageId, { include: ['field_banner_top','field_page_image', 'field_attachments'] })
            .then(res => {
                this.pageData = res[0].attributes
                this.fulldata = res[0]
                if(res[0].field_banner_top) this.bannerimg = process.env.VUE_APP_ENDPOINT + res[0].field_banner_top.attributes.uri.url
            })
    }
}
</script>

<style lang="scss" scoped>
@import "../styles/colors.scss";
</style>